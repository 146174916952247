import { action, makeObservable, observable, runInAction } from "mobx";
import { OptionProps } from "../components/Input/Input";
import { RootStore } from "./RootStore";
import Fuse from "fuse.js";

class InputStore {
  @observable public search: string = "";
  @observable public options: OptionProps[] = [];
  @observable public _options: OptionProps[] = [];


  constructor(root: RootStore) {
    makeObservable(this);
  }

  @action("Sets search")
  public setSearch(value: string) {
    this.search = value;
  }

  @action("Sets options")
  public setOptions(options: OptionProps[]) {
    this.options = options;
    this._options = options;
  }

  public searchOptions() {
    console.log("searching");
    if (this.search.trim() === "") {
      this.setOptions(this._options);
      return;
    }
    const fuse = new Fuse(this._options, { keys: ["text"] });
    const filteredOptions = fuse.search(this.search);
    runInAction(() => {
      this.options = filteredOptions.map((fo) => {
        return fo.item;
      });
    });
    console.log(this.options)
  }

}

export default InputStore;
