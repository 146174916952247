import { PathLike } from "fs";

const qs = require("qs");

export default {
  // baseURL: "http://localhost:8080/",
  // baseURL: "https://budgeteer.apps.customcodesign.com",
  baseURL:"",
  returnRejectionPromiseOnError: true,
  timeout: 30000,
  headers: {
    common: {
      "Cache-Control": "no-cache, no-store, must_revalidate",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin":"http://localhost:8100",
      Accept: "application/json",
    },
  },
  paramsSerializer: (params: PathLike) =>
    qs.stringify(params, { indeces: false }),
};
