import React, { useEffect } from "react";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonModal,
  IonSearchbar,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { RootStore } from "../../stores/RootStore";
import { inject, observer } from "mobx-react";
import UIStore from "../../stores/uiStore";

interface IModalProps {
  store: RootStore;
}

const Modal: React.FC<IModalProps> = (props: IModalProps) => {
  const { uiStore,  } = props.store.stores;

  useEffect(() => {
    //should get the options again
    console.log("modal should have refreshed");
  }, []);

  function getModalGivenType() {
    if (props.store.stores.uiStore.modalType == "select") {
      return (
        <IonModal
          isOpen={uiStore.showModal}
          cssClass="my-custom-class"
          onDidDismiss={() => {
            uiStore.setShowModal(false);
          }}
        >
          <IonHeader>
            <IonToolbar>
              <IonTitle slot="start">{uiStore.title}</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonSearchbar
            value={uiStore.modalSearchValue}
            onIonChange={(e) => {
              uiStore.setModalSearch(e.detail.value!);
              uiStore.modalSearchFunction();
              console.log(props.store.stores.entryStore.entry);
            }}
          />
          <IonContent>{uiStore.modalContents}</IonContent>
          {uiStore.modalFooter}
        </IonModal>
      );
    }
    return (
      <IonModal
        isOpen={uiStore.showModal}
        cssClass="my-custom-class"
        onDidDismiss={() => {
          uiStore.setShowModal(false);
        }}
      >
        <IonHeader>
          <IonToolbar>
            <IonTitle slot="start">{uiStore.title}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
        {uiStore.modalContents}</IonContent>
        <IonFooter>
          <IonToolbar>
            <IonButtons slot="end">
              <IonButton
                color="primary"
                fill="outline"
                shape="round"
                onClick={() => {
                  uiStore.setShowModal(false);
                }}
              >
                Close
              </IonButton>
              <IonButton
                fill="solid"
                color="success"
                shape="round"
                onClick={() => {
                  if (props.store.stores.uiStore.ok) {
                    props.store.stores.uiStore.ok()
                  }
                  uiStore.setShowModal(false);
                }}
              >
                OK
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonFooter>
      </IonModal>
    );
  }
  return getModalGivenType();
};

export default inject("store")(observer(Modal));
