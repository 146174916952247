import {
  IonItem,
  IonLabel,
  IonInput,
  IonDatetime,
  IonSelect,
  IonSelectOption,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonFooter,
  IonButtons,
  IonButton,
  IonCheckbox,
  IonRadio,
  IonRadioGroup,
  IonPopover,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonIcon,
  PickerOptions,
} from "@ionic/react";
import { createOutline } from "ionicons/icons";
import Fuse from "fuse.js";
import { inject, observer } from "mobx-react";
import moment from "moment";
import { FC, useEffect } from "react";
import { useState } from "react";
import { RootStore } from "../../stores/RootStore";
import "./Input.css";

type types = "text" | "number" | "email" | "password" | "date" | undefined;
type autoCompleteTypes =
  | undefined
  | "off"
  | "on"
  | "name"
  | "honorific-prefix"
  | "given-name"
  | "additional-name"
  | "family-name"
  | "honorific-suffix"
  | "nickname"
  | "email"
  | "username"
  | "new-password"
  | "current-password"
  | "one-time-code"
  | "organization-title"
  | "organization"
  | "street-address"
  | "address-level4"
  | "address-level3"
  | "address-level2"
  | "address-level1"
  | "country"
  | "country-name"
  | "postal-code"
  | "cc-name"
  | "cc-given-name"
  | "cc-additional-name"
  | "cc-family-name"
  | "cc-number"
  | "cc-exp"
  | "cc-exp-month"
  | "cc-exp-year"
  | "cc-csc"
  | "cc-type"
  | "transaction-currency"
  | "transaction-amount"
  | "language"
  | "bday"
  | "bday-day"
  | "bday-month"
  | "bday-year"
  | "sex"
  | "tel"
  | "tel-country-code"
  | "tel-national"
  | "tel-area-code"
  | "tel-local"
  | "tel-extension"
  | "impp"
  | "url"
  | "photo";

export interface InputProps {
  label?: string;
  value?: string | number | string[] | null;
  type: types;
  wholeNumber?: boolean;
  onBlur: Function;
  autocomplete?: autoCompleteTypes;
  required?: boolean;
  select?: boolean;
  ionSelect?: boolean;
  multiple?: boolean;
  checkbox?: boolean;
  hide?: boolean;
  sizeXs?: string;
  sizeMd?: string;
  options?: OptionProps[];
  onClick?: Function;
  store: RootStore;
  selectOK?: Function;
  warning?: string;
  popover?: boolean;
  popoverElements?: JSX.Element;
  popoverFunction?: Function;
  editInputSlider?: Function;
  clearInput?: boolean;
  pickerOptions?: any;
  pickOptions?: PickerOptions;
  defaultPickerOptions?: boolean;
  disabled?: boolean;
  key?: any;
}

export interface OptionProps {
  text: string;
  value: string;
}

const Input: FC<InputProps> = (props: InputProps) => {
  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });
  const { uiStore, inputStore } = props.store.stores;
  const [options, setoptions] = useState(props.options);
  const [_options, set_options] = useState(props.options); //this is the backup of the options
  const [checkTrue, setcheckTrue] = useState(false);
  inputStore.setOptions(props.options!);

  useEffect(() => {
    updateModalContents();
  }, [uiStore.modalSearchValue, options]);

  useEffect(() => {
    searchOptions();
  }, [uiStore.modalSearchValue]);

  useEffect(() => {}, [checkTrue, props.value]);

  useEffect(() => {}, [props.popoverElements]);

  const searchOptions = () => {
    if (uiStore.modalSearchValue == "") {
      setoptions(_options);
      return;
    }
    if (_options?.length! > 0) {
      const fuse = new Fuse(_options!, { keys: ["text"] });
      const filteredOptionsFuseType = fuse.search(uiStore.modalSearchValue);
      const filteredOptions = filteredOptionsFuseType.map((fo: any) => {
        return fo.item;
      });
      setoptions(filteredOptions);
    }
  };

  function updateModalContents() {
    uiStore.setModalContents(getInputOptions());
    uiStore.setModalFooter(getInputFooter());
  }

  function getInputFooter(): JSX.Element {
    return (
      <IonFooter>
        <IonToolbar>
          <IonButtons slot="end">
            <IonButton
              color="primary"
              fill="outline"
              shape="round"
              onClick={() => {
                uiStore.setModalSearch("");
                uiStore.setShowModal(false);
              }}
            >
              Close
            </IonButton>
            <IonButton
              fill="solid"
              color="success"
              shape="round"
              onClick={() => {
                if (props.selectOK) {
                  props.selectOK(uiStore.selected);
                }
                uiStore.setShowModal(false);
              }}
            >
              OK
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    );
  }

  function getInputOptions(): JSX.Element {
    if (options === undefined) {
      return <></>;
    }
    const optionItems = options!.map((option) => {
      return (
        <IonItem
          key={option.value}
          onClick={() => {
            uiStore.setSelected(option.value);
          }}
        >
          <IonLabel>{option.text}</IonLabel>
          <IonRadio slot="start" value={option.value} />
        </IonItem>
      );
    });
    const searchItem = (
      <IonItem
        onClick={() => {
          uiStore.setSelected(uiStore.modalSearchValue);
        }}
      >
        <IonLabel>Create New Entity: {uiStore.modalSearchValue}</IonLabel>
        <IonRadio slot="start" value={uiStore.modalSearchValue} />
      </IonItem>
    );
    // inputStore.search !== "" && optionItems.push(searchItem);
    uiStore.modalSearchValue !== "" && optionItems.push(searchItem);
    return (
      <IonRadioGroup value={uiStore.selected}>{optionItems}</IonRadioGroup>
    );
  }
  function getByInputType() {
    const { uiStore } = props.store.stores;
    if (props.select === true) {
      if (props.value !== "" && uiStore.selected == "") {
        uiStore.setSelected(props.value!.toString());
      }
      return (
        <IonInput
          autocomplete={props.autocomplete}
          type={props.type}
          value={uiStore.selected}
          onClick={() => {
            if (props.onClick !== undefined) {
              //get the modal ready with elements
              setoptions(props.options);
              set_options(props.options);
              uiStore.setShowModalType("select");
              uiStore.setModalContents(getInputOptions());
              uiStore.setModalFooter(getInputFooter());
              uiStore.setTitle(props.label!);
              uiStore.setModalOK(props.selectOK!);
              uiStore.setModalSearchFunction(searchOptions);
              // uiStore.setModalSearch("")
              props.onClick();
            }
          }}
          onBlur={(e) => {
            props.onBlur(e.target.value);
          }}
        ></IonInput>
      );
    } else if (props.type === "date") {
      const defaultPickerOptions = {
        backdropDismiss: false,
        buttons: [
          {
            text: "Clear",
            handler: (selected: any) => {
              props.onBlur(null);
            },
          },
          {
            text: "Cancel",
            role: "cancel",
          },
          {
            text: "Done",

            handler: (selected: any) => {
              const date = new Date(
                selected.year.value,
                selected.month.value-1,
                selected.day.value
              );
              props.onBlur(date);
            },
          },
        ],
      };
      return (
        <IonDatetime
          disabled={props.disabled}
          id={"iamdatepicker"}
          pickerOptions={
            props.defaultPickerOptions === true
              ? defaultPickerOptions
              : undefined
          }
          value={
            props.value === null || props.value === undefined
              ? null
              : props.value.toString()
          }
          max="3000-01-01"
          onIonChange={(e: any) => {
            if (e.detail.value === null || e.detail.value === undefined || e.detail.value === "Invalid date"){
              props.onBlur(e.detail.value)
            }else{
              props.onBlur(moment(e.detail.value).format("YYYY-MM-DDThh:mm:ssZ"));
            }
          }}
        />
      );
    } else if (props.checkbox === true) {
      if (props.popover === true) {
        const checked = props.value?.toString() === "true"
        return (
          <>
            <IonCheckbox
              checked={checked}
              // checked={props.value?.toString() === "true"}
              // value={props.value?.toString()}
              onClick={()=>{

                setShowPopover({ showPopover: checked, event: undefined });
              }}
              onIonChange={(e: any) => {
                props.onBlur(e.detail.checked);
                if (e.detail.checked === true){
                  setcheckTrue(true);
                }
                // props.onBlur(e.detail.checked);
              }}
            />
            <IonPopover
              isOpen={popoverState.showPopover}
              onDidDismiss={() =>
                setShowPopover({ showPopover: false, event: undefined })
              }
            >
              <IonHeader>
                <IonToolbar>
                  <IonTitle slot="start">{props.label!}</IonTitle>
                </IonToolbar>
              </IonHeader>
              {props.popoverElements!}
              <IonFooter>
                <IonToolbar>
                  <IonButtons slot="end">
                    <IonButton
                      color="primary"
                      fill="outline"
                      shape="round"
                      onClick={() => {
                        setShowPopover({
                          showPopover: false,
                          event: undefined,
                        });
                      }}
                    >
                      Close
                    </IonButton>
                    <IonButton
                      fill="solid"
                      color="success"
                      shape="round"
                      onClick={() => {
                        if (props.popoverFunction !== undefined) {
                          props.popoverFunction();
                        }
                        setShowPopover({
                          showPopover: false,
                          event: undefined,
                        });
                      }}
                    >
                      OK
                    </IonButton>
                  </IonButtons>
                </IonToolbar>
              </IonFooter>
            </IonPopover>
          </>
        );
      }
      return (
        <IonCheckbox
          value={props.value?.toString()}
          onIonChange={(e) => {
            props.onBlur(e.detail.checked);
          }}
        />
      );
    } else if (props.ionSelect === true) {
      return (
        <IonSelect
          value={props.value?.toString().split(",")}
          placeholder={props.label}
          onIonChange={(e) => {
            if (props.value?.toString() !== e.detail.value.toString()) {
              props.onBlur(e.detail.value + "");
            }
          }}
          multiple={props.multiple}
        >
          {props.options?.map((optionProp) => {
            return (
              <IonSelectOption key={optionProp.value} value={optionProp.value}>
                {optionProp.text}
              </IonSelectOption>
            );
          })}
        </IonSelect>
      );
    } else if (props.wholeNumber === true) {
      return (
        <IonInput
          disabled={props.disabled}
          autocomplete={props.autocomplete}
          type={props.type}
          value={
            props.value !== null ? parseInt(props.value!.toString(), 10) : null
          }
          clearInput={props.clearInput}
          onIonBlur={(e: any) => {
            if (e.detail.target.value === "") {
              props.onBlur(null);
            } else {
              const value = parseInt(
                e.detail.target.value!.toString(),
                10
              ).toFixed(0);
              props.onBlur(parseInt(value));
            }
          }}
        />
      );
    } else {
      return (
        <IonInput
          disabled={props.disabled}
          clearInput={props.clearInput}
          autocomplete={props.autocomplete}
          type={props.type}
          value={props.value!.toString()}
          onIonBlur={(e: any) => {
            props.onBlur(e.detail.target.value);
          }}
        />
      );
    }
  }

  if (props.editInputSlider !== undefined) {
    return (
      <>
        <IonItemSliding>
          <IonItemOptions side="end">
            <IonItemOption
              onClick={() => {
                props.editInputSlider!();
                setShowPopover({ showPopover: true, event: undefined });
              }}
            >
              <IonIcon
                slot="start"
                icon={createOutline}
                onClick={() => {
                  setShowPopover({ showPopover: true, event: undefined });
                }}
              />
            </IonItemOption>
          </IonItemOptions>
          <IonItem>
            <IonLabel position="floating">
              {props.required === true ? `${props.label}*` : props.label!}
            </IonLabel>
            {getByInputType()}
          </IonItem>
          <IonLabel style={{}} className={"inputWarning"}>
            {props.warning}
          </IonLabel>
        </IonItemSliding>
      </>
    );
  }

  return (
    <>
      <IonItem key={props.key}>
        <IonLabel position="floating">
          {props.required === true ? `${props.label}*` : props.label!}
        </IonLabel>
        {getByInputType()}
      </IonItem>
      <IonLabel style={{}} className={"inputWarning"}>
        {props.warning}
      </IonLabel>
    </>
  );
};

export default inject("store")(observer(Input));
