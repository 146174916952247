import { IonButton, IonCol, IonRow } from "@ionic/react";
import { inject, observer } from "mobx-react";
import { FC, useEffect, useState } from "react";
import { RootStore } from "../../stores/RootStore";
import Input, { InputProps } from "../Input/Input";

export interface CustomForm2Props {
  inputs: InputProps[];
  store: RootStore;
  submitFunction?: Function;
}

const CustomForm2 = observer((props: CustomForm2Props) => {
  return (
    <>
      {props.inputs.map((input, index) => {
        const warning = "";
        // console.log(`input ${input.label},value ${input.value}, hide: ${input.hide}`)
        console.log(`${input.label}: ${input.hide}`);
        if (input.hide === true) {
          return null;
        }
        return (
          <IonCol
            key={index}
            sizeXs={input.sizeXs ? input.sizeXs : "12"}
            sizeMd={input.sizeMd ? input.sizeMd : "4"}
          >
            <Input
              // key={inputValues.get(index)}
              checkbox={input.checkbox}
              store={input.store}
              label={input.label}
              type={input.type}
              popover={input.popover}
              popoverElements={input.popoverElements}
              clearInput={input.clearInput}
              disabled={input.disabled}
              onBlur={(e: any) => {
                input.onBlur(e);
              }}
              value={input.value}
              autocomplete={input.autocomplete}
              select={input.select}
              onClick={input.onClick}
              options={input.options}
              ionSelect={input.ionSelect}
              multiple={input.multiple}
              popoverFunction={input.popoverFunction}
              wholeNumber={input.wholeNumber}
              sizeMd={input.sizeMd}
              sizeXs={input.sizeXs}
              editInputSlider={input.editInputSlider}
              pickerOptions={input.pickerOptions}
              defaultPickerOptions={input.defaultPickerOptions}
              required={input.required}
              warning={warning}
            />
          </IonCol>
        );
      })}
      {/* {props.submitElement} */}
      {props.submitFunction !== undefined ? (
        <IonRow>
          <IonCol size-md="4">
            <IonButton
              expand="block"
              onClick={() => {
                props.submitFunction!();
              }}
            >
              Save
            </IonButton>
          </IonCol>
        </IonRow>
      ) : null}
    </>
  );
});

export default CustomForm2;
