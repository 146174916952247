import { AlertButton, IonicSafeString } from "@ionic/react";
import { action, makeObservable, observable } from "mobx";
import { RootStore } from "./RootStore";

type modalType = "select" | "default" | undefined;
type toastColors =
  | "primary"
  | "secondary"
  | "tertiary"
  | "success"
  | "warning"
  | "danger"
  | "light"
  | "medium"
  | "dark"
  | undefined;

interface alertOptions {
  show: boolean;
  headerText: string | undefined;
  message: string | IonicSafeString | undefined;
  buttons: (string | AlertButton)[] | undefined;
}

class UIStore {
  @observable public showModal: boolean = false;
  @observable public showToast: boolean = false;
  // @observable public showAlert: boolean = false;
  @observable public alertOpts: alertOptions = {
    show: false,
    headerText: "Alert",
    message: "",
    buttons: undefined,
  };
  @observable public alertHeader: string = "Alert!";
  @observable public modalType: modalType = undefined;
  @observable public modalContents: any;
  @observable public modalFooter: any;
  @observable public ok: Function = () => {};
  @observable public selected: string = "";
  @observable public title: string = "";
  @observable public toastMessage: string = "";
  @observable public toastColor: toastColors = undefined;
  @observable public modalSearchValue: string = "";
  @observable public modalSearchFunction: Function = () => {};

  private root: RootStore;

  constructor(root: RootStore) {
    this.root = root;
    makeObservable(this);
  }

  @action("sets the show alert value")
  public setShowAlert(value: boolean) {
    this.alertOpts.show = value;
  }

  @action("sets the show alert value")
  public triggerAlert(options: alertOptions) {
    this.alertOpts = options;
  }

  @action("Sets show modal to value")
  public setShowModal(value: boolean) {
    this.showModal = value;
  }

  @action("Sets the search term for the modal")
  public setModalSearch(value: string) {
    this.modalSearchValue = value;
  }

  @action("Sets the search term for the modal")
  public setModalSearchFunction(f: Function) {
    this.modalSearchFunction = f;
  }

  @action("Sets show toast to value")
  public setShowToast(value: boolean) {
    this.showToast = value;
  }

  @action("Sets toast color")
  public setToastColor(color: toastColors) {
    this.toastColor = color;
  }

  @action("Sets toast message to value")
  public setToastMessage(value: string) {
    this.toastColor = "success";
    this.showToast = true;
    this.toastMessage = value;
  }

  @action("Sets toast message to value")
  public setToastErrorMessage(value: string) {
    this.toastColor = "danger";
    this.showToast = true;
    this.toastMessage = value;
  }

  @action("Sets show modal type")
  public setShowModalType(value: modalType) {
    this.modalType = value;
  }

  @action("Sets modal contents")
  public setModalContents(content: JSX.Element) {
    this.modalContents = content;
  }

  @action("Sets modal footer")
  public setModalFooter(content: JSX.Element) {
    this.modalFooter = content;
  }

  @action("Sets modal ok")
  public setModalOK(f: Function) {
    this.ok = f;
  }

  @action("Sets selected")
  public setSelected(s: string) {
    this.selected = s;
  }

  @action("Sets title for modal")
  public setTitle(t: string) {
    this.title = t;
  }
}

export default UIStore;
