import { action, makeObservable, observable } from "mobx";
import moment from "moment";
import React from "react";
import { OptionProps } from "../components/Input/Input";
import EntryService from "../services/entryService";
import Entry from "./models/entry";
import SpecialEntry from "./models/specialEntry";
import { RootStore } from "./RootStore";

type repeatOption = "day" | "week" | "month" | "year" | undefined;

class EntryStore {
  @observable public specialEntries: SpecialEntry[] = [];
  @observable public entriesBySumOfEntities: Entry[] = [];
  @observable public negatives: SpecialEntry[] = [];
  @observable public daySpecialEntries: SpecialEntry[] = [];
  @observable public todaysBalance: number = 0.0;
  @observable day = "";
  @observable month = new Date().getMonth();
  @observable year = new Date().getFullYear();
  @observable public entry: Entry = new Entry();
  @observable entities: string[] = [];
  @observable recurring: boolean = false;
  @observable repeatEvery: number = 1; //1,2... number should not be greater than 10000
  @observable repeatOption: repeatOption = undefined; //day, week, month, year
  @observable endsOn: string|null = ""; //when should the occurences end after.
  @observable endsAfter: number|null = 1; // how many occurences should this end after.
  @observable repeatsOn: string[] = []; //0,1,2 these are the number representation for the weekdays
  @observable monthlyOnDay: string = ""; //Monthly on day 9 or Monthly on the second Monday


  @observable loading: boolean = true;

  @action("set loading")
  public setLoading(value: boolean) {
    this.loading = value;
  }

  private root: RootStore;
  private api: EntryService;

  constructor(root: RootStore) {
    this.root = root;
    this.api = this.root.services.entryService;
    makeObservable(this);
  }

  public getYearFromDay(): number {
    var check = moment(this.day, "YYYY/MM/DD");
    var year = check.format("YYYY");
    return parseInt(year);
  }
  public getMonthFromDay(): number {
    var check = moment(this.day, "YYYY/MM/DD");
    var month = check.format("M");
    return parseInt(month) - 1;
  }
  public getDayFromDay(): number {
    var check = moment(this.day, "YYYY/MM/DD");
    var day = check.format("D");
    return parseInt(day);
  }

  public async getSpecialEntries(month: number, year: number) {
    //still need to create ui store
    // const { ui } = this.root.stores;
    try {
      const specialEntries = await this.api.getSpecialEntries(month, year);
      this.setSpecialEntries(specialEntries);
    } catch (error) {
      // ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    }
  }

  public async getNegatives(limit: number) {
    //still need to create ui store
    // const { ui } = this.root.stores;
    try {
      const negativeEntries = await this.api.getNegatives(0, limit);
      this.setNegativeEntries(negativeEntries);
    } catch (error) {
      // ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    }
  }

  public async getSumByEntities(offset: number, limit: number) {
    //still need to create ui store
    // const { ui } = this.root.stores;
    try {
      const sumOfEntities = await this.api.getSumByEntities(offset, limit);
      this.setEntiresBySumOfEntities(sumOfEntities);
    } catch (error) {
      // ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    }
  }

  public async appendSumByEntities(offset: number, limit: number) {
    //still need to create ui store
    // const { ui } = this.root.stores;
    try {
      const sumOfEntities = await this.api.getSumByEntities(offset, limit);
      this.setEntiresBySumOfEntities(
        this.entriesBySumOfEntities.concat(sumOfEntities)
      );
    } catch (error) {
      // ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    }
  }

  public async appendNegatives(offset: number, limit: number) {
    //still need to create ui store
    // const { ui } = this.root.stores;
    try {
      const negatives = await this.api.getNegatives(offset, limit);
      this.setNegativeEntries(this.negatives.concat(negatives));
    } catch (error) {
      // ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    }
  }

  public async createEntry() {
    //still need to create ui store
    const { uiStore } = this.root.stores;
    try {
      await this.api.createEntry(this.entry);
      this.resetEntry();
      uiStore.setSelected("");
      uiStore.setToastMessage("Entry created!");
    } catch (error) {
      // ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    }
  }

  public async markEntryDone(entryID: number) {
    //still need to create ui store
    const { uiStore } = this.root.stores;
    try {
       await this.api.markEntryDone(entryID);
      this.resetEntry();
      uiStore.setSelected("");
      uiStore.setToastMessage("Entry updated!");
    } catch (error) {
      // ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    }
  }

  public async updateEntry(future:boolean) {
    //still need to create ui store
    const { uiStore } = this.root.stores;
    try {
      await this.api.updateEntry(this.entry, future);
      this.resetEntry();
      uiStore.setSelected("");
      uiStore.setToastMessage("Entry updated!");
    } catch (error) {
      // ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    }
  }

  public async deleteEntry(id: number, recurring: boolean) {
    //still need to create ui store
    const { uiStore } = this.root.stores;
    try {
      await this.api.deleteEntry(id, recurring);
      //remove entry from special entries
      this.removeEntry(id);
      this.resetEntry();
      uiStore.setSelected("");
    } catch (error) {
      // ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    }
  }

  public async getSpecialEntriesByDay(day: string) {
    //still need to create ui store
    // const { ui } = this.root.stores;
    try {
      const specialEntries = await this.api.getSpecialEntriesByDay(day);
      this.setDailySpecialEntries(specialEntries);
    } catch (error) {
      // ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    }
  }

  public async fetchEntityOptions() {
    //still need to create ui store
    // const { ui } = this.root.stores;
    try {
      const entities = await this.api.fetchEntityOptions();
      this.setEntities(entities);
    } catch (error) {
      // ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    }
  }

  public async fetchEntry(id: number): Promise<Entry> {
    //still need to create ui store
    // const { ui } = this.root.stores;
    try {
      const entry = await this.api.fetchEntry(id);
      console.log(entry)
      this.setEntry(entry);
      return entry;
    } catch (error) {
      // ui.showToast({ message: error.response.data.message, color: "danger" });
      throw error;
    } finally {
      //set the ui store loading observable to false
    }
  }

  public getEntityOptions(): OptionProps[] {
    if (this.entities.length > 0) {
      const entitiesMap = this.entities.map((e) => {
        const entityOption = {
          text: e,
          value: e,
        };
        return entityOption;
      });
      return entitiesMap;
    } else {
      return [];
    }
  }

  @action("Set Special Entries")
  private setSpecialEntries(specialEntries: SpecialEntry[]) {
    this.specialEntries = specialEntries;
  }

  @action("Set Negative Entries")
  private setNegativeEntries(negativeEntries: SpecialEntry[]) {
    this.negatives = negativeEntries;
  }

  @action("Set Negative Entries")
  private setEntiresBySumOfEntities(entries: Entry[]) {
    this.entriesBySumOfEntities = entries;
  }

  @action("reset entries by sum of entities")
  public resetEntriesBySumOfEntities() {
    this.entriesBySumOfEntities = [];
  }

  @action("reset negatives")
  public resetNegatives() {
    this.negatives = [];
  }

  @action("Set Daily Special Entries")
  public setDailySpecialEntries(specialEntries: SpecialEntry[]) {
    this.daySpecialEntries = specialEntries;
  }

  @action("Clear Special Entries")
  public clearSpecialEntries() {
    this.specialEntries = [];
  }

  @action("Set Todays Balance")
  public setTodaysBalance(balance: number) {
    this.todaysBalance = balance;
  }

  @action("Set Current Day")
  setDay(day: string) {
    this.day = day;
  }

  @action("Reset Entry")
  resetEntry() {
    this.entry = new Entry();
  }

  @action("Set Entities")
  setEntities(entities: string[]) {
    this.entities = entities;
  }

  @action("Set Entry")
  setEntry(entry: Entry) {
    this.entry = entry;
  }

  @action("Remove Entry From Arrays") removeEntry(entryID: number) {
    const index = this.specialEntries.findIndex((e) => e.Id === entryID);
    this.specialEntries.splice(index, 1);
    const whatsLeft = this.specialEntries;
    this.setSpecialEntries(whatsLeft);
  }

  @action("Sets Year") setYear(year: number) {
    this.year = year;
  }

  @action("Sets Month") setMonth(month: number) {
    this.month = month;
  }

  @action("Decrement Month")
  decrementMonth() {
    this.clearSpecialEntries();
    if (this.month - 1 >= 0) {
      this.setMonth(this.month - 1);
    } else {
      this.setMonth(11);
      this.setYear(this.year - 1);
    }
  }

  @action("Increment Month")
  incrementMonth() {
    this.clearSpecialEntries();
    if (this.month + 1 < 11) {
      this.setMonth(this.month + 1);
    } else {
      this.setMonth(0);
      this.setYear(this.year + 1);
    }
  }

  @action("set recurring")
  setRecurring(v: boolean) {
    this.recurring = v;
  }

  @action("resets the recurring fields")
  resetRecurring() {
    this.recurring = false;
    //set all the other fields in the entry object to their default values.
  }

}

export default EntryStore;