import { IonRow, IonCol } from "@ionic/react";
import { inject, observer } from "mobx-react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import SpecialEntry from "../../stores/models/specialEntry";
import { RootStore } from "../../stores/RootStore";

interface ICalendarBox {
    empty?: boolean;
    date?: number;
    data?: SpecialEntry[];
    balance: number;
    divStyle?: string;
    dateStyle?: string;
    leftOverStyle?: string;
    nameOfWeek?: string;
    fullDate?: string;
    store: RootStore;
    fullData?: SpecialEntry[];
    today?:boolean;
  }
  const CalendarBox: React.FC<ICalendarBox> = (props: ICalendarBox) => {
    let history = useHistory();
    const [dataToShow, setdataToShow] = useState(props.data);
    
    useEffect(() => {
        setdataToShow(props.data)
    }, [props.data])

    function returnNumberRow(amount: number) {
      return (
        <IonRow
          style={{ marginTop: "3px" }}
          className={
            amount > 0 ? "calendar_box_row_positive" : "calendar_box_row_negative"
          }
        >{`€${amount.toFixed(2)}`}</IonRow>
      );
    }
  
    return (
      <div
        onClick={() => {
          const { entryStore } = props.store.stores;
          entryStore.setDailySpecialEntries(props.fullData!);
          entryStore.setTodaysBalance(props.balance);
          // entryStore.setDay(moment(props.date!).format("YYYY-MM-DD"));
          history.push(
            `/day/${moment(props.fullDate).format(
              "YYYY-MM-DD"
            )}?balance=${props.balance.toFixed(2)}`
          );
        }}
        className={
          props.divStyle !== undefined ? props.divStyle : "calendar_box_div"
        }
      >
        {props.empty === false ? (
          <>
            {props.date ? (
              <IonRow style={{ fontSize: "90%" }}>
                <IonCol
                  className={
                    props.dateStyle !== undefined
                      ? props.dateStyle
                      : "calendar_box_date"
                  }
                >
                  <span className={props.today === true?"calendar_box_date_circle_today":"calendar_box_date_circle"}>{props.date}</span>
                </IonCol>
              </IonRow>
            ) : null}
            <IonRow
              className={
                props.leftOverStyle !== undefined
                  ? props.leftOverStyle
                  : "calendar_box_left_over"
              }
            >
              <IonCol style={{ padding: "0px" }}>
                {props.nameOfWeek ? (
                  <IonRow style={{}}>{props.nameOfWeek}</IonRow>
                ) : (
                  <>
                    {dataToShow?.map((item) => {
                      if(item.Amount !== 0 && item.Entity !== ""){
                        return returnNumberRow(item.Amount);
                      }
                    })}
                    {returnNumberRow(props.balance)}
                  </>
                )}
              </IonCol>
            </IonRow>
          </>
        ) : (
          <div
            style={{
              height: "100%",
              width: "100%",
              backgroundColor: "rgba(1, 1, 1, 0.3)",
            }}
          ></div>
        )}
      </div>
    );
  };
  CalendarBox.defaultProps = {
    empty: false,
  };

  export default inject("store")(observer(CalendarBox));  