import {
  IonButtons,
  IonContent,
  IonGrid,
  IonHeader,
  IonItem,
  IonLabel,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  withIonLifeCycle,
} from "@ionic/react";
import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import moment from "moment";
import React from "react";
import InfiniteScroll from "../components/infiniteScroll/InfiniteScroll";
import { RootStore } from "../stores/RootStore";
import "./EntryNegatives.css";

interface IEntryNegativesProps {
  store: RootStore;
}

@inject("store")
@observer
class EntryNegatives extends React.Component<IEntryNegativesProps> {

  @observable offset:number=20;
  @action('set offset')
  setOffset(offset:number){
    this.offset = offset
  }

  ionViewWillEnter() {
    const { entryStore } = this.props.store.stores;
    this.reset()
    entryStore.appendNegatives(0, 20)
  }

  reset(){
    const { entryStore } = this.props.store.stores;
    entryStore.resetNegatives()
    this.setOffset(20)
  }

  getNegatives(): JSX.Element {
    const { entryStore } = this.props.store?.stores;
    return (
      <IonGrid>
        {entryStore.negatives.map((negative) => {
          return (
            <IonItem>
              <IonLabel>{moment(negative.TransactionDay).format("DD-MM-YYYY")}</IonLabel>
              <IonLabel style={{textAlign:"right"}} slot="end">€{negative.Balance.toFixed(2)}</IonLabel>
            </IonItem>
          );
        })}
      </IonGrid>
    );
  }

  async fetchData(){
const {entryStore} = this.props.store.stores
    await entryStore.appendSumByEntities(this.offset, 20);
    const newOffset = this.offset + 20
    this.setOffset(newOffset)

  }


  render() {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
            <IonTitle>Negatives</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent fullscreen>
          <IonHeader collapse="condense">
            <IonToolbar>
              <IonTitle size="large">EntryNegatives</IonTitle>
            </IonToolbar>
          </IonHeader>
          <InfiniteScroll
            items={this.getNegatives()}
            fetchData={() => {
              this.fetchData()
            }}
          />
        </IonContent>
      </IonPage>
    );
  }
}

export default withIonLifeCycle(EntryNegatives);
