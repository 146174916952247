import React, { useEffect, useState } from "react";
import { IonAlert } from "@ionic/react";
import { RootStore } from "../../stores/RootStore";
import { inject, observer } from "mobx-react";
import { runInAction } from "mobx";

interface IAlertProps {
  store: RootStore;
}

const Alert: React.FC<IAlertProps> = (props: IAlertProps) => {
  const { uiStore } = props.store.stores;
  const [showAlert, setShowAlert] = useState(false);

  return (
    <IonAlert
      isOpen={uiStore.alertOpts.show}
      onDidDismiss={() => {
        uiStore.setShowAlert(false);
      }}
      cssClass="my-custom-class"
      header={uiStore.alertOpts.headerText}
      message={uiStore.alertOpts.message}
      buttons={uiStore.alertOpts.buttons}
    />
  );
};

export default inject("store")(observer(Alert));
