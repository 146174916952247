import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("specialEntry")
class SpecialEntry {

  @JsonProperty("id", Number)
  Id: number = 0;

  @JsonProperty("amount", Number)
  Amount: number = 0.00;

  @JsonProperty("transaction_day", String)
  TransactionDay: string = "";

  @JsonProperty("entity", String)
  Entity: string = "";

  @JsonProperty("done", Boolean)
  Done: boolean = false;

  @JsonProperty("comments", String)
  Comments: string = "";

  @JsonProperty("balance", Number)
  Balance: number = 0.00;

  @JsonProperty("recurrence_id", Number, true)
  RecurrenceID: number|null = 0;

  @JsonProperty("day_of_week", Number)
  DayOfWeek: number = 0;


}

export default SpecialEntry;
