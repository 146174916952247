import Registration from "../stores/models/registration";
import { BaseService, IResponse } from "./BaseService";

export default class AuthService extends BaseService {
  public setAuthToken(name: "token", token: string): void {
    this.http.defaults.headers.common[name] = "token " + token;
  }
  public async login(email: string, pass: string): Promise<IResponse> {
    try {
      const resp = await this.http.post<IResponse>("/api/v1/login", {
        email: email,
        password: pass,
      });
      const data: IResponse = resp.data;
      if (data.success && data.data) {
        this.setAuthToken("token", data.data);
      }
      return data;
    } catch (error) {
      throw error;
    }
  }
  public async register(inputs: any, password: string): Promise<IResponse> {
    try {
      const body = this.encodeObject<Registration>(inputs, Registration);
      const resp = await this.http.post<IResponse>(
        `/api/v1/users/register?password=${encodeURIComponent(password)}`,
        body
      );
      const data: IResponse = resp.data;
      return data;
    } catch (error) {
      throw error;
    }
  }
}
