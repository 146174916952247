import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  withIonLifeCycle,
} from "@ionic/react";
import {
  addCircle,
  chevronBackCircle,
  chevronForwardCircle,
} from "ionicons/icons";
import { runInAction } from "mobx";
import { inject, observer } from "mobx-react";
import moment from "moment";
import React from "react";
import { RouteComponentProps } from "react-router";
import DayList from "../components/dayList/DayList";
import { RootStore } from "../stores/RootStore";
import "./Day.css";

interface IDayProps {
  store: RootStore;
}

interface RouteProps {
  day: string;
  balance: string;
}

@inject("store")
@observer
class Day extends React.Component<IDayProps & RouteComponentProps<RouteProps>> {
  ionViewWillEnter() {
    const { entryStore } = this.props.store.stores;
    if (this.props.match.params.day !== undefined) {
      entryStore.setDay(this.props.match.params.day);
      entryStore.getSpecialEntriesByDay(this.props.match.params.day);
    } else {
      entryStore.getSpecialEntriesByDay(
        moment(new Date()).format("YYYY-MM-DD")
      );
    }
  }

  render() {
    const { entryStore } = this.props.store.stores;
    if (this.props.match.params.day !== "" && entryStore.day === "") {
      entryStore.setDay(this.props.match.params.day);
    }
    let balance = 0.0;
    if (entryStore.daySpecialEntries.length > 0) {
      balance =
        entryStore.daySpecialEntries[entryStore.daySpecialEntries.length - 1]
          .Balance;
    }
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
            <IonTitle>{`${moment(entryStore.day).format(
              "DD-MM-YYYY"
            )}`}</IonTitle>
            <IonButtons slot="end">
              <IonButton
              routerDirection="forward"
                onClick={() => {
                  console.log("create new entry");
                  entryStore.resetEntry();
                  this.props.history.push(
                    `/entry/0?day=${moment(entryStore.day).format(
                      "YYYY-MM-DD"
                    )}`
                  );
                }}
              >
                <IonIcon slot="icon-only" icon={addCircle} />
              </IonButton>
              <IonButton
                onClick={() => {
                  console.log("back");
                  console.log(entryStore.day);
                  const newDay = moment(entryStore.day)
                    .add(-1, "days")
                    .format("YYYY-MM-DD");
                  entryStore.setDay(newDay);
                  entryStore.getSpecialEntriesByDay(newDay);
                  console.log(entryStore.day);
                }}
              >
                <IonIcon slot="icon-only" icon={chevronBackCircle} />
              </IonButton>
              <IonButton
                onClick={() => {
                  console.log("forward");
                  const newDay = moment(entryStore.day)
                    .add(1, "days")
                    .format("YYYY-MM-DD");
                  entryStore.setDay(newDay);
                  entryStore.getSpecialEntriesByDay(newDay);
                  console.log(entryStore.day);
                }}
              >
                <IonIcon slot="icon-only" icon={chevronForwardCircle} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent fullscreen>
          <DayList
            date={entryStore.day}
            store={this.props.store!}
            items={entryStore.daySpecialEntries}
          />
        </IonContent>
        <IonFooter>
          <IonToolbar>
            <IonTitle
              slot="end"
              style={{
                color: balance > 0 ? "rgba(0,255,0,.6" : "rgba(255,0,0,.6",
              }}
            >
              €{balance.toFixed(2)}
            </IonTitle>
          </IonToolbar>
        </IonFooter>
      </IonPage>
    );
  }

  // async getDataFromApi(day: string) {
  //   await this.props.store.stores.entryStore.getSpecialEntriesByDay(day);
  // }
}

export default withIonLifeCycle(Day);
