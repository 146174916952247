import { IonButton, IonCol, IonRow } from "@ionic/react";
import { inject, observer } from "mobx-react";
import { FC, useEffect, useState } from "react";
import { RootStore } from "../../stores/RootStore";
import Input, { InputProps } from "../Input/Input";

export interface CustomFormProps {
  //we need to inject inputs into the form
  inputs: InputProps[];
  // content:JSX.Element;
  //we also need to handle the submit from inside here so the submit button logic will have to be passed into here
  // submitElement:JSX.Element;
  store: RootStore;
  submitFunction?: Function;
}

const CustomForm= observer((props: CustomFormProps) => {
  //we need to use state to control
  const [inputsOk, setinputsOk] = useState<Map<number, string | undefined>>(
    new Map<number, string | undefined>()
  );
  const [inputValues, setinputValues] = useState<Map<number, any>>(
    new Map<number, any>()
  );
  const [s, sets] = useState("");

  const getInputWarningByIndex = (index: number): string => {
    const warning = inputsOk.get(index);
    return warning!;
  };

  // useEffect(() => {
  //   props.inputs.map((input, index) => {
  //     if (input.value === "" && input.required === true) {
  //       inputsOk.set(index, undefined);
  //     } else {
  //       inputsOk.set(index, "");
  //     }
  //   });
  // }, [props.inputs, s]);

  // useEffect(() => {}, [inputsOk, s]);

  // useEffect(()=>{},[props.store.stores.entryStore.entry])

  const preSubmitValidation = (): Boolean => {
    //when saving check and make sure that all the inputsOk are without text else you will have to update
    sets(new Date().getTime().toString());
    //if all the inputOks are empty then this means that the form is fine
    let ok = true;
console.log(inputsOk)
    // check value for each required
    props.inputs.map((input, index)=>{
      inputsOk.set(index, undefined)
      if (input.required === true ){
        if (input.value!.toString().trim() === ""){
          console.log(input.label)
          console.log(input.value)
          inputsOk.set(index, input.warning)
          ok = false
        }
      }
    })
    return ok;
  };
  return (
    <>
      {props.inputs.map((input, index) => {
        const warning = getInputWarningByIndex(index);
        if (input.hide === true) {
          return null;
        }
        return (
          <IonCol
            key={index}
            sizeXs={input.sizeXs ? input.sizeXs : "12"}
            sizeMd={input.sizeMd ? input.sizeMd : "4"}
          >
            <Input
            // key={inputValues.get(index)}
              checkbox={input.checkbox}
              store={input.store}
              label={input.label}
              type={input.type}
              popover={input.popover}
              popoverElements={input.popoverElements}
              clearInput={input.clearInput}
              disabled={input.disabled}
              onBlur={(e: any) => {
                input.onBlur(e);
              }}
              value={input.value}
              autocomplete={input.autocomplete}
              select={input.select}
              onClick={input.onClick}
              options={input.options}
              ionSelect={input.ionSelect}
              multiple={input.multiple}
              popoverFunction={input.popoverFunction}
              wholeNumber={input.wholeNumber}
              sizeMd={input.sizeMd}
              sizeXs={input.sizeXs}
              editInputSlider={input.editInputSlider}
              pickerOptions={input.pickerOptions}
              defaultPickerOptions={input.defaultPickerOptions}
              selectOK={(e: any) => {
                if (input.required) {
                  const value = e as String;
                  if (value.trim() === "") {
                    inputsOk.set(index, input.warning!);
                  } else {
                    inputsOk.set(index, "");
                  }
                }
                inputValues.set(index, e);
                sets(new Date().getTime().toString());
                input.selectOK!(e);
              }}
              required={input.required}
              warning={warning}
            />
          </IonCol>
        );
      })}
      {/* {props.submitElement} */}
      {props.submitFunction !== undefined ? (
        <IonRow>
          <IonCol size-md="4">
            <IonButton
              expand="block"
              onClick={() => {
                const ok = preSubmitValidation();
                if (ok === true) {
                  props.submitFunction!();
                } else {
                }
              }}
            >
              Save
            </IonButton>
          </IonCol>
        </IonRow>
      ) : null}
    </>
  );
});

export default CustomForm;
