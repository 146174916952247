import {
  IonButton,
  IonButtons,
  IonGrid,
  IonIcon,
  IonRow,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";
import "./Calendar.css";
import SpecialEntry from "../../stores/models/specialEntry";
import moment from "moment";
import { chevronBackCircle, chevronForwardCircle } from "ionicons/icons";
import { RootStore } from "../../stores/RootStore";
import { inject, observer } from "mobx-react";
import CalendarBox from "./CalendarBox";

interface ICalendarMonthView {
  year: number;
  month: number;
  left: Function;
  right: Function;
  store: RootStore;
}

const CalendarMonthView: React.FC<ICalendarMonthView> = (
  props: ICalendarMonthView
) => {
  function getRowsOfData() {
    const height = window.innerHeight - 400;
    const amount = Math.floor(height / 150);
    return amount;
  }
  const { entryStore } = props.store.stores;
  const daysOfWeekShort = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const filterEntriesByDate = (date: Date): SpecialEntry[] => {
    return entryStore.specialEntries.filter((entry: SpecialEntry) => {
      const entryDate = moment(entry.TransactionDay);
      const dateMoment = moment(date);

      if (entryDate.isSame(dateMoment, "day")) {
        return entry;
      }
    });
  };
  const getCalendarRows = () => {
    let rows: JSX.Element[] = [];
    let lastBalance: number = 0;
    const date = new Date(props.year, props.month, 1);
    while (date.getMonth() === props.month) {
      const dateItems = filterEntriesByDate(date);
      let dow = 0;
      if (dateItems.length > 0) {
        dow = dateItems[0].DayOfWeek;
      } else {
        dow = date.getDay();
      }
      let calendarBoxes: JSX.Element[] = [];
      //for example 3 then return 3 empty calendar boxes together with the 4 full ones inside a row
      for (let i = 0; i < dow; i++) {
        calendarBoxes.push(
          <CalendarBox store={props.store!} date={0} balance={0} empty />
        );
      }
      while (dow < 7) {
        var iscurrentDate = moment(date).isSame(new Date(), "day");
        if (iscurrentDate) {
          console.log(date)
        }
        //the below calendar box will reflect the current date(items for the date are in the dateItems)
        const dateItems = filterEntriesByDate(date);
        if (dateItems.length === 0) {
          //push an empty calendar box with last Balance since we do not have any items for this day
          calendarBoxes.push(
            <CalendarBox
            today={iscurrentDate}
              store={props.store!}
              date={date.getDate()}
              fullDate={moment(date).format()}
              fullData={dateItems}
              data={dateItems.slice(0, getRowsOfData())}
              balance={lastBalance}
              empty={date.getMonth() !== props.month}
            />
          );
        } else {
          //set the new balance since we have items for this day
          lastBalance = dateItems[dateItems.length - 1].Balance;
          calendarBoxes.push(
            <CalendarBox
            today={iscurrentDate}
              store={props.store!}
              fullDate={moment(date).format()}
              date={date.getDate()}
              fullData={dateItems}
              data={dateItems.slice(0, getRowsOfData())}
              balance={lastBalance}
            />
          );
          if (date.getDate() === 30) {
          }
        }
        dow++;
        date.setDate(date.getDate() + 1);
      }

      const row = <IonRow className={"calendar_row"}>{calendarBoxes}</IonRow>;
      rows.push(row);
      //get items from special antries with this date
      //check the dow if example = 3 then create 3 empty calendarBoxes in current rows since dow is 0 based and start filling in the third one with the info
      //add 1 to date: date.setDate(date.getDate() + 1);
      //continue until you hit 7
      //start another row and repeat until you complete the month
    }
    return rows;
  };
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <>
      <IonGrid style={{ height: "80%" }}>
        <IonRow>
          <IonToolbar>
            <IonTitle>{`${months[props.month]}-${props.year}`}</IonTitle>
            <IonButtons slot="end">
              <IonButton
                onClick={() => {
                  props.left();
                }}
              >
                <IonIcon slot="icon-only" icon={chevronBackCircle} />
              </IonButton>
              <IonButton
                onClick={() => {
                  props.right();
                }}
              >
                <IonIcon slot="icon-only" icon={chevronForwardCircle} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonRow>
        <IonRow style={{ height: "5%" }}>
          {daysOfWeekShort.map((e) => {
            return (
              <CalendarBox
                store={props.store!}
                leftOverStyle={"calendar_box_week_day"}
                dateStyle={""}
                divStyle="calendar_box_week_day"
                balance={0}
                nameOfWeek={e}
              />
            );
          })}
        </IonRow>
        {entryStore.specialEntries.length <= 0 ? (
          <IonSpinner />
        ) : (
          getCalendarRows()
        )}
      </IonGrid>
    </>
  );
};
export default inject("store")(observer(CalendarMonthView));
