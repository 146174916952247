import {
  IonBadge,
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonList,
  IonNote,
} from "@ionic/react";
import {
  checkboxSharp,
  checkmarkCircleSharp,
  closeCircleSharp,
  create,
  text,
  trash,
} from "ionicons/icons";
import { inject, observer } from "mobx-react";
import moment from "moment";
import React, { useEffect } from "react";
import SpecialEntry from "../../stores/models/specialEntry";
import { RootStore } from "../../stores/RootStore";
import "./DayList.css";

interface IDayList {
  store: RootStore;
  items: SpecialEntry[];
  date: string;
}

const DayList: React.FC<IDayList> = (props: IDayList) => {
  const { entryStore, uiStore } = props.store.stores;
  function getItemRow(entry: SpecialEntry) {
    // if (entryDate.isSame(dateMoment, "day")) {
    //   return entry;
    // }
    if (!moment(entry.TransactionDay).isSame(moment(props.date), "day")) {
      return <></>;
    }

    const deletedEntry = async () => {
      if (entry.RecurrenceID!==0){
        uiStore.triggerAlert({
          show: true,
          headerText: "Update Future Events",
          message:
            "Clicking ok will update all future events that are attatched to this recurring event",
          buttons: [
            {
              text: "No",
              cssClass: "secondary",
              handler: async () => {
                await entryStore.deleteEntry(entry.Id, false);
                await entryStore.getSpecialEntriesByDay(entryStore.day);
                await entryStore.getSpecialEntries(
                  entryStore.getMonthFromDay(),
                  entryStore.getYearFromDay()
                );
              },
            },
            {
              text: "Yes",
              handler: async () => {
                await entryStore.deleteEntry(entry.Id, true);
                await entryStore.getSpecialEntriesByDay(entryStore.day);
                await entryStore.getSpecialEntries(
                  entryStore.getMonthFromDay(),
                  entryStore.getYearFromDay()
                );
              },
            },
          ],
        });
      }else{
        await entryStore.deleteEntry(entry.Id, false);
        await entryStore.getSpecialEntriesByDay(entryStore.day);
        await entryStore.getSpecialEntries(
          entryStore.getMonthFromDay(),
          entryStore.getYearFromDay()
        );
      }
    };

    const markEntryDone = async () => {
      await entryStore.markEntryDone(entry.Id);
      entryStore.getSpecialEntriesByDay(entryStore.day);
    };

    return (
      <div>
        <IonItemSliding>
          {entry.Done !== true ? (
            <IonItem>
              {entry.Comments.trim() !== "" && (
                <IonBadge color="tertiary">
                  <IonIcon icon={text} />
                </IonBadge>
              )}
              <IonLabel>{entry.Entity}</IonLabel>
              <IonNote
                style={{
                  color:
                    entry.Amount > 0 ? "rgba(0,255,0,.6" : "rgba(255,0,0,.6",
                }}
                slot="end"
              >
                €{entry.Amount.toFixed(2)}
              </IonNote>
            </IonItem>
          ) : (
            <IonItem >
              {entry.Comments.trim() !== "" && (
                <IonBadge color="tertiary">
                  <IonIcon icon={text} />
                </IonBadge>
              )}
              <IonLabel>{entry.Entity}</IonLabel>
              <IonIcon icon={checkboxSharp}/>
              <IonNote
                style={{
                  color:
                    entry.Amount > 0 ? "rgba(0,255,0,.6" : "rgba(255,0,0,.6",
                }}
                slot="end"
              >
                €{entry.Amount.toFixed(2)}
              </IonNote>
            </IonItem>
          )}
          <IonItemOptions side="end">
            <IonItemOption
              color="danger"
              onClick={() => {
                deletedEntry();
              }}
            >
              <IonIcon slot="start" icon={trash} />
            </IonItemOption>
            <IonItemOption
              onClick={() => {
                window.location.href = `/entry/${entry.Id}`;
              }}
            >
              <IonIcon slot="start" icon={create} />
            </IonItemOption>
            {entry.Comments.trim() !== "" && (
              <IonItemOption
                color="secondary"
                onClick={() => {
                  uiStore.setModalContents(<>{entry.Comments}</>);
                  uiStore.setShowModalType("default");
                  uiStore.setShowModal(true);
                  uiStore.setTitle("Comments");
                  // uiStore.setModalOK(props.selectOK!);
                  console.log(entry.Comments);
                }}
              >
                <IonIcon slot="start" icon={text} />
              </IonItemOption>
            )}
            {entry.Done === false && (
              <IonItemOption
                color="success"
                onClick={() => {
                  markEntryDone();
                }}
              >
                <IonIcon slot="start" icon={checkmarkCircleSharp} />
              </IonItemOption>
            )}
            {entry.Done === true && (
              <IonItemOption
                color="danger"
                onClick={() => {
                  markEntryDone();
                }}
              >
                <IonIcon slot="start" icon={closeCircleSharp} />
              </IonItemOption>
            )}
          </IonItemOptions>
        </IonItemSliding>
      </div>
    );
  }

  useEffect(() => {}, [entryStore.daySpecialEntries]);

  return (
    <IonList>
      {props.items.map((entry) => {
        return getItemRow(entry);
      })}
    </IonList>
  );
};

export default inject("store")(observer(DayList));
