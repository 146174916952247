import {
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { inject, observer } from "mobx-react";
import React, { useState } from "react";
import { RootStore } from "../stores/RootStore";
import { InputProps } from "../components/Input/Input";
import "./Register.css";
import Registration from "../stores/models/registration";
import CustomForm from "../components/customForm/CustomForm";

interface IRegister {
  store: RootStore;
}

// function validateEmail(email: string) {
//   const re =
//     /^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))$/;
//   return re.test(String(email).toLowerCase());
// }

const Register: React.FC<IRegister> = ({ store }) => {
  const [name, setName] = useState<string>("");
  const [lastName, setlastName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [dob, setDOB] = useState<string>("");
  const inputs: InputProps[] = [
    {
      autocomplete: "off",
      store: store,
      label: "First Name",
      value: name,
      type: "text",
      onBlur: setName,
      required: true,
      warning:"First Name required"
    },
    {
      autocomplete: "off",
      store: store,
      label: "Last Name",
      value: lastName,
      type: "text",
      onBlur: setlastName,
      required: true,
      warning:"Last Name required"
    },
    {
      autocomplete: "off",
      store: store,
      label: "Date Of Birth",
      value: dob,
      type: "date",
      onBlur: setDOB,
      required: true,
      warning:"Date Of Birth required"
    },
    {
      autocomplete: "off",
      store: store,
      label: "Email",
      value: email,
      type: "text",
      onBlur: setEmail,
      required: true,
      warning:"Email required"
    },
    {
      autocomplete: "off",
      store: store,
      label: "Password",
      value: password,
      type: "password",
      onBlur: setPassword,
      required: true,
      warning:"Password required"
    },
  ];

  const registerUser = () => {
    const { authStore } = store.stores;
    const registrationData: Registration = new Registration();
    registrationData.Name = name;
    registrationData.LastName = lastName;
    registrationData.Email = email;
    registrationData.DOB = dob;

    authStore.register(registrationData, password);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Registration</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="ion-padding ion-text-center">
        <IonGrid className="loginGrid">
          <IonRow>
            <IonCol>Registration</IonCol>
          </IonRow>

          <CustomForm
          store={store}
              inputs={inputs}
              submitFunction={() => {
                registerUser();
              }}
            />
          {/* {inputs.map((input) => {
            return (
              <IonRow>
                <IonCol>
                  <Input
                    store={store}
                    label={input.label}
                    type={input.type}
                    onBlur={input.onBlur}
                    value={input.value}
                    autocomplete={input.autocomplete}
                    required={input.required}
                    warning={input.warning}
                  />
                </IonCol>
              </IonRow>
            );
          })}
          <IonRow>
            <IonCol>
              <p style={{ fontSize: "small" }}>
                By clicking REGISTER you agree to our <a href="#">Policy</a>
              </p>
              <IonButton expand="block" onClick={registerUser}>
                Register
              </IonButton>
            </IonCol>
          </IonRow> */}
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default inject("store")(observer(Register));
