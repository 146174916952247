import React, { useEffect } from "react";
import { IonToast } from "@ionic/react";
import { RootStore } from "../../stores/RootStore";
import { inject, observer } from "mobx-react";

interface IToastProps {
  store: RootStore;
}

const Toast: React.FC<IToastProps> = (props: IToastProps) => {
  const { uiStore } = props.store.stores;

  useEffect(() => {
    //should get the options again
  }, []);

  return (
    <IonToast
    animated
      color={uiStore.toastColor}
      isOpen={uiStore.showToast}
      onDidDismiss={() => {
        uiStore.setShowToast(false);
        uiStore.setToastColor('primary')
      }}
      message={uiStore.toastMessage}
      duration={3000}
    />
  );
};

export default inject("store")(observer(Toast));
