import { JsonObject, JsonProperty } from "json2typescript";
import { makeAutoObservable, makeObservable, observable } from "mobx";

@JsonObject("entry")
class Entry {
  
  @observable
  @JsonProperty("id", Number)
  ID: number = 0;

  @observable
  @JsonProperty("amount", Number)
  Amount: number = 0.0;

  @observable
  @JsonProperty("transaction_day", String)
  TransactionDay: string = "";

  @observable
  @JsonProperty("comments", String)
  Comments: string = "";

  @observable
  @JsonProperty("recurring", Boolean)
  Recurring: boolean = false;

  @observable
  @JsonProperty("done", Boolean)
  Done: boolean = false;

  @observable
  @JsonProperty("user_id", Number)
  UserID: number = 0;

  @observable
  @JsonProperty("recurrence_id", Number,true)
  RecurrenceID: number|null = 0;
  
  @observable
  @JsonProperty("entity", String)
  Entity: string = "";

  @observable
  @JsonProperty("action_type", String)
  ActionType: string = "";

  @observable
  @JsonProperty("repeat_every", Number, [])
  RepeatEvery: number = 1;

  @observable
  @JsonProperty("repeat_option", String, [])
  RepeatOption: string = "day";

  @observable
  @JsonProperty("repeats_on", String, [])
  RepeatsOn: string = "day";//mon, tues, wed...
  
  @observable
  @JsonProperty("ends_on", String, [])
  // EndsOn: string = moment(new Date).format('YYYY-MM-DD') + 'T00:00:00Z'
  EndsOn:string|null = null

  @observable
  @JsonProperty("ends_after", Number, [])
  EndsAfter: number|null = 1

  @observable
  @JsonProperty("monthly_on_day", String, [])
  MonthlyOnDay: string = "1"
  
  // https://mobx.js.org/understanding-reactivity.html
  constructor() {
      makeObservable(this)
  }
  
}

export default Entry;
