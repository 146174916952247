import { IonButton, IonContent, useIonViewWillEnter } from "@ionic/react";
import React, { useEffect, useState } from "react";
import "./Calendar.css";
import { RootStore } from "../../stores/RootStore";
import { inject, observer } from "mobx-react";
import CalendarMonthView from "./CalendarMonthView";

interface ICalendarProps {
  // specialEntries: SpecialEntry[];
  store: RootStore;
}
const Calendar: React.FC<ICalendarProps> = (props: ICalendarProps) => {
  //since we are changin the month/year here in the calendar component then it stands to reason that we get the data inside the component
  const { entryStore } = props.store!.stores;
  console.log(entryStore.year);
  console.log(entryStore.month);

  async function getDataFromApi() {
    await entryStore.getSpecialEntries(entryStore.month, entryStore.year);
  }
  useEffect(() => {
    getDataFromApi();
  }, [entryStore.month]);

  useIonViewWillEnter(() => {
    getDataFromApi();
    console.log("ionViewWillEnter event fired");
  });

  useEffect(() => {
    var min_horizontal_move = 30;
    var max_vertical_move = 30;
    var within_ms = 1000;

    var start_xPos = 0;
    var start_yPos = 0;
    let start_time = new Date();
    function touch_start(event: any) {
      start_xPos = event.touches[0].pageX;
      start_yPos = event.touches[0].pageY;
      start_time = new Date();
    }

    function touch_end(event: any) {
      var end_xPos = event.changedTouches[0].pageX;
      var end_yPos = event.changedTouches[0].pageY;
      let end_time: Date = new Date();
      let move_x = end_xPos - start_xPos;
      let move_y = end_yPos - start_yPos;
      let elapsed_time = end_time.getTime() - start_time.getTime();
      if (
        Math.abs(move_x) > min_horizontal_move &&
        Math.abs(move_y) < max_vertical_move &&
        elapsed_time < within_ms
      ) {
        if (move_x < 0) {
          //alert("left");
          entryStore.incrementMonth();
        } else {
          //alert("right");
          entryStore.decrementMonth();
        }
      }
    }

    const content = document.getElementById("monthViewer");
    content!.addEventListener("touchstart", touch_start);
    content!.addEventListener("touchend", touch_end);

    return () => {
      content!.removeEventListener("touchstart", touch_start);
      content!.removeEventListener("touchend", touch_end);
      // cleanup function .... remove listeners
    };
  }, []);

  return (
    <IonContent id="monthViewer">
      <CalendarMonthView
        store={props.store}
        month={entryStore.month} //0 based
        year={entryStore.year}
        left={() => {
          entryStore.decrementMonth();
        }}
        right={() => {
          entryStore.incrementMonth();
        }}
      />
    </IonContent>
  );
};

export default inject("store")(observer(Calendar));
