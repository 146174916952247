import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
} from "@ionic/react";

import { useLocation } from "react-router-dom";
import {  alertOutline, bugOutline, calendarOutline, homeOutline, list, listOutline, logOutOutline } from "ionicons/icons";
import "./Menu.css";
import { useState } from "react";
import { RootStore } from "../stores/RootStore";

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
  function?: (store: RootStore) => void;
}

const appPages: AppPage[] = [
  {
    title: "Home",
    url: "/home",
    iosIcon: homeOutline,
    mdIcon: homeOutline,
  },
  {
    title: "Monthly",
    url: "/month",
    iosIcon: calendarOutline,
    mdIcon: calendarOutline,
  },
  {
    title: "Daily",
    url: "/day",
    iosIcon: calendarOutline,
    mdIcon: calendarOutline,
  },
  {
    title: "Entity Totals",
    url: "/entityTotals",
    iosIcon: listOutline,
    mdIcon: listOutline,
  },
  {
    title: "Negatives",
    url: "/negatives",
    iosIcon: alertOutline,
    mdIcon: alertOutline,
  },
  {
    title: "Logout",
    url: "/logout",
    iosIcon: logOutOutline,
    mdIcon: logOutOutline,
    function: (store) => {
      store.services.storage.rem("token");
    },
  },
  {
    title: "Recent Fixes",
    url: "/recentFixes",
    iosIcon: bugOutline,
    mdIcon: bugOutline,
  },
];

// const labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
interface IMenu {
  store: RootStore;
}
const Menu: React.FC<IMenu> = (stores) => {
  const location = useLocation();
  const [tab, setTab] = useState("Main");
  function logout(f: (store: RootStore) => void) {
    f(stores.store);
    window.location.reload(true);
  }
  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <IonListHeader>{tab}</IonListHeader>
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem
                  onClick={() => {
                    appPage.function !== undefined
                      ? logout(appPage.function)
                      : setTab(appPage.title);
                  }}
                  className={
                    location.pathname === appPage.url ? "selected" : ""
                  }
                  routerLink={appPage.url}
                  routerDirection="none"
                  lines="none"
                  detail={false}
                >
                  <IonIcon
                    slot="start"
                    ios={appPage.iosIcon}
                    md={appPage.mdIcon}
                  />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>

        {/* <IonList id="labels-list">
          <IonListHeader>Labels</IonListHeader>
          {labels.map((label, index) => (
            <IonItem lines="none" key={index}>
              <IonIcon slot="start" icon={bookmarkOutline} />
              <IonLabel>{label}</IonLabel>
            </IonItem>
          ))}
        </IonList> */}
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
