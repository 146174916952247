import {
  IonButton,
  IonContent,
  IonGrid,
  IonHeader,
  IonItem,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
  withIonLifeCycle,
} from "@ionic/react";
import { inject, observer } from "mobx-react";
import React from "react";
import { RootStore } from "../stores/RootStore";
import { InputProps } from "../components/Input/Input";
import "./Entry.css";
import { runInAction } from "mobx";
import { RouteComponentProps } from "react-router";
import moment from "moment";
import CustomForm from "../components/customForm/CustomForm";
import RecurringForm from "../components/RecurringForm/RecurringForm";
import EntryStore from "../stores/entryStore";

interface IEntry {
  store: RootStore;
  backURL: string;
}

interface RouteParams {
  id: string;
  day: string;
}

@inject("store")
@observer
class Entry extends React.Component<IEntry & RouteComponentProps<RouteParams>> {
  async ionViewWillEnter() {
    const { entryStore, uiStore } = this.props.store.stores;
    entryStore.fetchEntityOptions();
    if (this.props.match.params.id !== "0") {
      await this.getDataFromApi().then((entry) => {
        if (entry) {
          entryStore.setLoading(false);
        }
      });
    } else {
      uiStore.setSelected("");
      entryStore.resetEntry();
      runInAction(() => {
        const urlParams = new URLSearchParams(this.props.location.search);
        const dayString = urlParams.get("day");
        const day = moment(dayString).format("YYYY-MM-DDT00:00:00Z");
        entryStore.entry.TransactionDay = day;
      });
    }
  }

  getInputs(): InputProps[] {
    const { entryStore, uiStore } = this.props.store.stores;
    // const recurringInputs = this.getRecurringInputs();
    const contents = <RecurringForm store={this.props.store} />;
    let inputs: InputProps[] = [
      {
        autocomplete: "off",
        store: this.props.store,
        label: "Amount",
        value: entryStore.entry.Amount.toFixed(2),
        type: "number",
        onBlur: (v: any) => {
          runInAction(() => {
            entryStore.entry.Amount = parseFloat(v);
          });
        },
        required: true,
        warning: "Amount is required",
      },
      {
        autocomplete: "off",
        store: this.props.store,
        label: "Transaction Date",
        value: entryStore.entry.TransactionDay,
        type: "date",
        onBlur: (v: any) => {
          runInAction(() => {
            entryStore.entry.TransactionDay = v;
          });
        },
        required: true,
        warning: "Transaction Date is required",
      },
      {
        autocomplete: "off",
        store: this.props.store,
        label: "Recurring",
        checkbox: true,
        value: String(entryStore.entry.Recurring),
        type: "text",
        popover: true,
        editInputSlider: () => {
          runInAction(() => {
            // entryStore.entry.Recurring = true;
          });
        },
        popoverElements: contents,
        popoverFunction: () => {},
        onBlur: (v: boolean) => {
            runInAction(() => {
              entryStore.entry.Recurring = v;
            });
        },
      },
      {
        autocomplete: "off",
        store: this.props.store,
        label: "Entity",
        value: entryStore.entry.Entity,
        type: "text",
        select: true,
        onBlur: (v: any) => {
          runInAction(() => {
            entryStore.entry.Entity = v;
          });
        },
        onClick: () => {
          uiStore.setShowModal(true);
        },
        options: entryStore.getEntityOptions(),
        required: true,
        selectOK: (v: any) => {
          runInAction(() => {
            entryStore.entry.Entity = v;
          });
        },
        warning: "Entity is required",
      },
      {
        autocomplete: "off",
        store: this.props.store,
        label: "Comments",
        value: entryStore.entry.Comments,
        type: "text",
        onBlur: (v: any) => {
          runInAction(() => {
            entryStore.entry.Comments = v;
          });
        },
      },
    ];
    return inputs;
  }

  saveEntry = () => {
    const { entryStore, uiStore } = this.props.store.stores;
    console.log(entryStore.entry)
    if (this.props.match.params.id !== "0") {
      if (entryStore.entry.Recurring === true && entryStore.entry.RecurrenceID !== 0) {
        //before updating entry check if user wants to update future ones if the entry.recurring is true
        uiStore.triggerAlert({
          show: true,
          headerText: "Update Future Events",
          message:
            "Clicking ok will update all future events that are attatched to this recurring event",
          buttons: [
            {
              text: "No",
              cssClass: "secondary",
              handler: () => {
                entryStore.updateEntry(false);
                console.log(`Confirm Cancel: blah`);
                this.props.history.goBack();
              },
            },
            {
              text: "Yes",
              handler: () => {
                entryStore.updateEntry(true);
                console.log("Confirm Okay");
                this.props.history.goBack();
              },
            },
          ],
        });
      } else {
        entryStore.updateEntry(entryStore.entry.Recurring === true && entryStore.entry.RecurrenceID === 0);
        this.props.history.goBack();
      }
    } else {
      entryStore.createEntry();
      this.props.history.goBack();
    }
  };

  requiredOK = () => {};

  render() {
    const { entryStore } = this.props.store.stores;
    const inputs = this.getInputs();
    // return entryStore.loading===true?<>loading</>:<>not loading any more</>
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Entry</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen className="ion-padding ion-text-center">
          <IonGrid className="">
            <CustomForm
              store={this.props.store}
              inputs={inputs}
              submitFunction={() => {
                this.saveEntry();
              }}
            />
          </IonGrid>
        </IonContent>
      </IonPage>
    );
  }

  private getDataFromApi(): Promise<any> {
    const { entryStore } = this.props.store!.stores!;
    return Promise.all([
      //async calls
      entryStore.fetchEntry(parseInt(this.props.match.params.id)),
    ]);
  }
}

export default withIonLifeCycle(Entry);
