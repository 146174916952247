import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("registration")
class Registration {

  @JsonProperty("name", String)
  Name: string = "";

  @JsonProperty("last_name", String)
  LastName: string = "";

  @JsonProperty("dob", String)
  DOB: string|null = "";

  @JsonProperty("email", String)
  Email: string = "";


}

export default Registration;
