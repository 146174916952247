import axios from "axios";
import API_CONFIG from "../services/api_config";
import AuthService from "../services/authService";
import EntryService from "../services/entryService";
import StorageService from "../services/storageService";
import AuthStore from "./authStore";
import EntryStore from "./entryStore";
import InputStore from "./inputStore";
import UIStore from "./uiStore";

//import stores
//import services

interface IServices {
  //declare the services here
  storage: StorageService;
  authService: AuthService;
  entryService: EntryService;
}

interface IStores {
  //declare the stores here
  authStore: AuthStore;
  uiStore: UIStore;
  entryStore: EntryStore;
  inputStore:InputStore;
}

class RootStore {
  //declare services and stores in the root store
  services: IServices;
  stores: IStores;

  //need to declare constructor for services and stores
  constructor() {
    // declare http to send into the services
    const http = axios.create(API_CONFIG);
    this.services = {
      storage: new StorageService(),
      authService: new AuthService(http),
      entryService:new EntryService(http),
    };
    this.stores = {
      authStore: new AuthStore(this),
      entryStore: new EntryStore(this),
      uiStore: new UIStore(this),
      inputStore:new InputStore(this),
    };
  }
}
const rootStore = new RootStore();
export { rootStore, RootStore };
