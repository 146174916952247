import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { inject, observer } from "mobx-react";
import React from "react";
import Calendar from "../components/calendar/Calendar";
import { RootStore } from "../stores/RootStore";
import "./Month.css";

interface IMonthProps {
  store: RootStore;
}

@inject("store")
@observer
class Month extends React.Component<IMonthProps> {

  render() {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
            <IonTitle>Month</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent id={"monthViewer"} fullscreen>
          <IonHeader collapse="condense">
            <IonToolbar>
              <IonTitle size="large">Month</IonTitle>
            </IonToolbar>
          </IonHeader>
          <Calendar store={this.props.store} />
        </IonContent>
      </IonPage>
    );
  }
}

export default Month;
