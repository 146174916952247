import {
  IonApp,
  IonRouterOutlet,
  IonSplitPane,
  setupConfig,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route } from "react-router-dom";
import Menu from "./components/Menu";
import Home from "./pages/Home";
import { Provider, inject, observer } from "mobx-react";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import { rootStore, RootStore } from "./stores/RootStore";
import React from "react";
import { configure, spy } from "mobx";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Month from "./pages/Month";
import Day from "./pages/Day";
import Entry from "./pages/Entry";
import Modal from "./components/modal/modal";
import Toast from "./components/toast/toast";
import Alert from "./components/alert/alert";
import EntityTotals from "./pages/EntityTotals";
import EntryNegatives from "./pages/EntryNegatives";
import RecentFixes from "./pages/RecentFixes";

setupConfig({
  animated: true,
  mode: "md",
});

configure({ enforceActions: "observed" });
// configure({
//   enforceActions: "always",
//   computedRequiresReaction: true,
//   reactionRequiresObservable: true,
//   observableRequiresReaction: true,
//   disableErrorBoundaries: true
// })

// spy(event => {
//   if (event.type === "action") {
//       console.log(`${event.name} with args: ${event.arguments}`)
//   }
// })

const App: React.FC = () => {
  return (
    <Provider store={rootStore}>
      <Main store={rootStore} />
    </Provider>
  );
};

@inject("store")
@observer
class Main extends React.Component<{ store: RootStore }> {
  buildMain() {
    return (
      <IonReactRouter>
        <IonSplitPane contentId="main">
          <Menu store={this.props.store} />
          <IonRouterOutlet id="main">
            {/*TODO can declare routes in array of objects. */}
            <Route path="/" exact={true}>
              <Redirect to="/home" />
            </Route>
            <Route path="" exact={true}>
              <Redirect to="/home" />
            </Route>
            <Route path="/home" exact={true} component={Home} />
            <Route path="/month" exact={true} component={Month} />
            <Route path="/day/:day" component={Day} />
            <Route path="/day" component={Day} exact />
            <Route path="/entry/:id" component={Entry} />
            <Route path="/entityTotals/" component={EntityTotals} />
            <Route path="/negatives/" component={EntryNegatives} />
            <Route path="/recentFixes" component={RecentFixes} />
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    );
  }

  render() {
    const { authStore } = this.props.store!.stores;
    return (
      <IonApp>
        {authStore.isLoggedIn ? (
          this.buildMain()
        ) : (
          <IonReactRouter>
            <IonRouterOutlet id="main">
              <Route path="/register" exact={true}>
                <Register store={this.props.store} />
              </Route>
              <Login store={this.props.store} />
            </IonRouterOutlet>
          </IonReactRouter>
        )}
        <Modal store={this.props.store} />
        <Toast store={this.props.store} />
        <Alert store={this.props.store}/>
      </IonApp>
    );
  }
}

export default App;
