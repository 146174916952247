import {
  IonButton,
  IonButtons,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  withIonLifeCycle,
} from "@ionic/react";
import { chevronDownCircleOutline } from "ionicons/icons";
import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import moment from "moment";
import React from "react";
import InfiniteScroll from "../components/infiniteScroll/InfiniteScroll";
import { RootStore } from "../stores/RootStore";
import "./EntityTotals.css";

interface IEntityTotalsProps {
  store: RootStore;
}

@inject("store")
@observer
class EntityTotals extends React.Component<IEntityTotalsProps> {
  @observable from: string = "";
  @observable to: string = moment(new Date()).format('YYYY-MM-DD');
  @observable offset: number = 20;
  @action("set offset")
  setOffset(offset: number) {
    this.offset = offset;
  }

  ionViewWillEnter() {
    const { entryStore } = this.props.store.stores;
    this.reset();
    entryStore.appendSumByEntities(0, 20);
  }

  reset() {
    const { entryStore } = this.props.store.stores;
    entryStore.resetEntriesBySumOfEntities();
    this.setOffset(20);
  }

  getEntriesBySumOfEntities(): JSX.Element {
    const { entryStore } = this.props.store?.stores;
    return (
      <IonGrid>
        {entryStore.entriesBySumOfEntities.map((sumOfEntity) => {
          return (
            <IonItem>
              <IonLabel>{sumOfEntity.Entity}</IonLabel>
              <IonLabel style={{ textAlign: "right" }} slot="end">
                €{sumOfEntity.Amount.toFixed(2)}
              </IonLabel>
            </IonItem>
          );
        })}
      </IonGrid>
    );
  }

  async fetchData() {
    const { entryStore } = this.props.store.stores;
    await entryStore.appendSumByEntities(this.offset, 20);
    const newOffset = this.offset + 20;
    this.setOffset(newOffset);
  }

  render() {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
            <IonTitle>EntityTotals</IonTitle>
          </IonToolbar>
              <IonButton fill="clear" className="dropdown-menu-button">
                <IonIcon slot="icon-only" icon={chevronDownCircleOutline} />
              </IonButton>
        </IonHeader>

        <IonContent fullscreen>
          <InfiniteScroll
            items={this.getEntriesBySumOfEntities()}
            fetchData={() => {
              this.fetchData();
            }}
          />
        </IonContent>
      </IonPage>
    );
  }
}

export default withIonLifeCycle(EntityTotals);
