import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonMenuButton,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  withIonLifeCycle,
} from "@ionic/react";
import { warning } from "ionicons/icons";
import { inject, observer } from "mobx-react";
import moment from "moment";
import React from "react";
import { RootStore } from "../stores/RootStore";
import "./Home.css";

interface IHomeProps {
  store: RootStore;
}

@inject("store")
@observer
class Home extends React.Component<IHomeProps> {
  ionViewWillEnter() {
    const { entryStore } = this.props.store.stores;
    entryStore.getNegatives(3);
    entryStore.getSumByEntities(0, 3);
  }

  getNegatives(): JSX.Element {
    const { entryStore } = this.props.store?.stores;
    return (
      <IonGrid>
        {entryStore.negatives.map((negative) => {
          return (
            <IonItem
              routerLink={`/day/${moment(negative.TransactionDay).format(
                "YYYY-MM-DD"
              )}`}
              routerDirection="forward"
            >
              <IonLabel>
                {moment(negative.TransactionDay).format("DD-MM-YYYY")}
              </IonLabel>
              <IonLabel slot="end">€{negative.Balance.toFixed(2)}</IonLabel>
            </IonItem>
          );
        })}
      </IonGrid>
    );
  }

  getEntriesBySumOfEntities(): JSX.Element {
    const { entryStore } = this.props.store?.stores;
    return (
      <IonGrid>
        {entryStore.entriesBySumOfEntities.map((sumOfEntity) => {
          return (
            <IonItem>
              <IonLabel>{sumOfEntity.Entity}</IonLabel>
              <IonLabel slot="end">€{sumOfEntity.Amount.toFixed(2)}</IonLabel>
            </IonItem>
          );
        })}
      </IonGrid>
    );
  }

  render() {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
            <IonTitle>Home</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent fullscreen>
          <IonHeader collapse="condense">
            <IonToolbar>
              <IonTitle size="large">Home</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonRow>
            <IonCol sizeMd="12" sizeLg="6">
              <IonCard>
                <IonItem>
                  <IonIcon icon={warning} slot="start" />
                  <IonLabel>Negatives!</IonLabel>
                  <IonButton fill="outline" slot="end"
                    routerDirection="forward"
                    routerLink="/negatives">
                    View All
                  </IonButton>
                </IonItem>
                <IonCardContent>{this.getNegatives()}</IonCardContent>
              </IonCard>
            </IonCol>

            <IonCol sizeMd="12" sizeLg="6">
              <IonCard>
                <IonItem>
                  <IonIcon icon={warning} slot="start" />
                  <IonLabel>Totals</IonLabel>
                  <IonButton
                    fill="outline"
                    slot="end"
                    routerDirection="forward"
                    routerLink="/entityTotals"
                  >
                    View All
                  </IonButton>
                </IonItem>
                <IonCardContent>
                  {this.getEntriesBySumOfEntities()}
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
          {/* Dashboard to go here with widgets, forecasts etc. Example would be
          <p>a list of days that are in the negative</p>
          <p>
            another example would be to show average of this month compared to
            the heighest month and the lowest month
          </p>
          <p>
            fejn morna zmerc: jekk is soltu tonfoq 50 euros restaurants, dax
            xaghar infaqna izjed(ibda min lola wiehed)
          </p> */}
        <div
          style={{
            position: "absolute",
            right: "20px",
            bottom: "0px",
            display: "flex",
            zIndex:1000,
            fontSize:11,
          }}
          id="version"
        >
          V0.1.1
        </div>
        </IonContent>
      </IonPage>
    );
  }
}

export default withIonLifeCycle(Home);
