import {
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonList,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { bugOutline } from "ionicons/icons";
import { inject, observer } from "mobx-react";
import React from "react";
import "./RecentFixes.css";

interface IRecentFixes {}

const BugFixes = [
  "Version 0.1",
  "Entry input values with required cannot be empty",
  "Registration form with entries that are required cannot be left empty",
  "Registration date of birth can be dated back to 1900 tested",
  "Version 0.1.1",
  "Fixed back issue that was exiting app.",
  "Version 0.2.0-alpha",
  "Added recurrence and fixed some issues with store not refreshing, updated custom form with new logic because of store changes.",
];

const RecentFixes: React.FC<IRecentFixes> = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Month</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonList>
          {BugFixes.map((bf) => {
            return (
              <IonItem>
                <IonIcon color="success" icon={bugOutline} />
                {bf}
              </IonItem>
            );
          })}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default inject("store")(observer(RecentFixes));
