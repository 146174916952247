import { action, runInAction } from "mobx";
import { inject, observer } from "mobx-react";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import OptionProp from "../../stores/models/optionProps";
import { RootStore } from "../../stores/RootStore";
import CustomForm from "../customForm/CustomForm";
import CustomForm2 from "../customForm2/CustomForm2";
import { InputProps } from "../Input/Input";

export interface RecurringFormProps {
  store: RootStore;
}

const RecurringForm: FC<RecurringFormProps> = (props: RecurringFormProps) => {
  const { entryStore } = props.store.stores;
  const [repeatOption, setRepeatOption] = useState(
    entryStore.entry.RepeatOption
  );
  // const [endsOn, setEndsOn] = useState<string | null>(entryStore.entry.EndsOn);
  // const [endsAfter, setEndsAfter] = useState<number | null>(
  //   entryStore.entry.EndsAfter
  // );
  // const [recurringInputs, setrecurringInputs] = useState(getRecurringInputs());

  useEffect(() => {
    console.log(repeatOption[0]);
    // let ri = getRecurringInputs();
    // setrecurringInputs(ri);
  }, [
    repeatOption,
    // entryStore.entry.EndsOn,
    // entryStore.entry.EndsAfter,
    // endsOn,
    // endsAfter,
  ]);

  console.log(repeatOption);
  console.log(entryStore.entry.RepeatOption.toString());

  function getNthWeekDayOfMonth(date: string): number {
    if (date === "" || date === undefined) {
      return 0;
    }
    var d = new Date(date);
    const originalDate = new Date(date);
    let n = 0;
    d.setDate(1);
    while (d.getDay() !== originalDate.getDay()) {
      //increment d by 1 until you have a match for day of week for original date
      d.setDate(d.getDate() + 1);
    }
    // //else we could end up with an infinite loop
    while (d.getTime() !== originalDate.getTime() && n < 10) {
      d.setDate(d.getDate() + 7);
      n++;
    }
    if (n === 10) {
      return 0;
    }
    return n;
  }

  function getRecurringInputs(): InputProps[] {
    //place this in a FC for itself so that the items can update or else they will not auto hide
    const { entryStore } = props.store.stores;
    const options: OptionProp[] = [
      { text: "Day", value: "day" },
      { text: "Week", value: "week" },
      { text: "Month", value: "month" },
      { text: "Year", value: "year" },
    ];
    const repeatsOnOptions: OptionProp[] = [
      { text: "Monday", value: "1" },
      { text: "Tuesday", value: "2" },
      { text: "Wednesday", value: "3" },
      { text: "Thursday", value: "4" },
      { text: "Friday", value: "5" },
      { text: "Saturday", value: "6" },
      { text: "Sunday", value: "0" },
    ];
    const daysOfTheWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const OrdinalNumbers = ["1st", "2nd", "3rd", "4th", "5th", "6th"];
    const MonthlyOptions: OptionProp[] = [
      {
        text: `day ${moment(entryStore.entry.TransactionDay).date()}`,
        value: `${moment(entryStore.entry.TransactionDay).date()}`,
      },
      {
        text: `${
          OrdinalNumbers[getNthWeekDayOfMonth(entryStore.entry.TransactionDay)]
        } ${daysOfTheWeek[moment(entryStore.entry.TransactionDay).day()]}`,
        value: `${getNthWeekDayOfMonth(
          entryStore.entry.TransactionDay
        )}:${moment(entryStore.entry.TransactionDay).day()}`,
      },
    ];
    let inputs: InputProps[] = [
      {
        autocomplete: "off",
        store: props.store,
        label: "Repeat every",
        // value: parseInt(entryStore.repeatEvery!.toString(), 10),
        value: entryStore.entry.RepeatEvery!,
        type: "number",
        sizeXs: "1",
        wholeNumber: true,
        onBlur: (v: number) => {
          runInAction(() => {
            entryStore.entry.RepeatEvery = v;
          });
        },
        required: true,
        warning: "Repeat every is required!",
      },
      {
        autocomplete: "off",
        store: props.store,
        label: "Repeat option",
        value: entryStore.entry.RepeatOption,
        type: "text",
        ionSelect: true,
        options: options,
        onBlur: (v: any) => {
          // setRepeatOption(v[0]);
          runInAction(() => {
            console.log(v);
            entryStore.entry.RepeatOption = v;
            console.log(entryStore.entry.RepeatOption);
          });
        },
        warning: "Repeat options is required!",
      },
      {
        autocomplete: "off",
        store: props.store,
        label: "Repeats on",
        value: entryStore.entry.RepeatsOn,
        type: "text",
        ionSelect: true,
        multiple: true,
        hide: entryStore.entry.RepeatOption.toString() !== "week",
        options: repeatsOnOptions,
        onBlur: (v: string) => {
          runInAction(() => {
            if (v !== null) {
            }
            // entryStore.entry.RepeatsOn = v!.join(",");
            entryStore.entry.RepeatsOn = v!;
          });
        },
        warning: "Repeat options is required!",
      },
      {
        autocomplete: "off",
        store: props.store,
        label: "Monthly On",
        value: entryStore.entry.MonthlyOnDay,
        ionSelect: true,
        type: "text",
        options: MonthlyOptions,
        hide: entryStore.entry.RepeatOption.toString() !== "month",
        onBlur: (v: any) => {
          runInAction(() => {
            entryStore.entry.MonthlyOnDay = v;
          });
        },
        warning: "Monthly on required!",
      },
      {
        autocomplete: "off",
        store: props.store,
        label: "Ends On",
        value: entryStore.entry.EndsOn,
        type: "date",
        defaultPickerOptions: true,
        // disabled:entryStore.entry.EndsAfter !== null,
        onBlur: (v: any) => {
          // setEndsOn(v);
          runInAction(() => {
            if (entryStore.entry.EndsOn !== v) {
              entryStore.entry.EndsAfter = null;
              entryStore.entry.EndsOn = v;
            }
          });
        },
        warning: "Ends On or Ends After required!",
      },
      {
        autocomplete: "off",
        store: props.store,
        label: "Ends After",
        value: entryStore.entry.EndsAfter,
        wholeNumber: true,
        type: "number",
        clearInput: true,
        // disabled:entryStore.entry.EndsOn !== null,
        onBlur: (v: number) => {
          // setEndsAfter(v);
          console.log(v);
          runInAction(() => {
            entryStore.entry.EndsAfter = v;
            entryStore.entry.EndsOn = null;
            console.log(`just set ends after to ${v}`);
          });
        },
        warning: "Ends On or Ends After required!",
      },
    ];
    return inputs;
  }
  let recurringInputs = getRecurringInputs();
  // console.log("got items again because of a change")
  return (
    <>
      <CustomForm2 inputs={recurringInputs} store={props.store} />
    </>
  );
};

export default inject("store")(observer(RecurringForm));
