import React, { useState } from "react";
import "./InfiniteScroll.css";
import { IonInfiniteScroll, IonInfiniteScrollContent } from "@ionic/react";
import { inject, observer } from "mobx-react";

interface IInfiniteScroll {
  items: JSX.Element;
  fetchData: Function;
}

const InfiniteScroll: React.FC<IInfiniteScroll> = (props: IInfiniteScroll) => {
  const [disableInfiniteScroll, setDisableInfiniteScroll] =
    useState<boolean>(false);

  const searchNext = async ($event: CustomEvent<void>) => {
    await props.fetchData();
    ($event.target as HTMLIonInfiniteScrollElement).complete();
  };

  // useEffect(() => {}, [entryStore.daySpecialEntries]);

  return (
    <>
      {props.items}
      <IonInfiniteScroll
        threshold="200px"
        disabled={disableInfiniteScroll}
        onIonInfinite={(e: CustomEvent<void>) => searchNext(e)}
      >
        <IonInfiniteScrollContent loadingText="Loading more good doggos..."></IonInfiniteScrollContent>
      </IonInfiniteScroll>
    </>
  );
};

export default inject("store")(observer(InfiniteScroll));
