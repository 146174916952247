import { computed, observable, runInAction } from "mobx";
import moment from "moment";
import Registration from "./models/registration";
import { RootStore } from "./RootStore";

class AuthStore {
  @observable public token: string = "";

  private root: RootStore;

  constructor(root: RootStore) {
    this.root = root;
    this.init();
  }

  init() {
    const { storage } = this.root.services;
    runInAction(async () => {
      this.token = storage.get("token");
      if (this.token !== "") {
        this.root.services.authService.setAuthToken("token", this.token);
        // const t = this.parseToken(this.token); //this we need to use to get data from the token to know who is logged in
      }
    });
  }

  // private parseToken(token: string) {
  //   if (!token || token.trim() != "") {
  //     return null;
  //   }
  //   var base64Url = token.split(".")[1];
  //   var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  //   var jsonPayload = decodeURIComponent(
  //     atob(base64)
  //       .split("")
  //       .map(function (c) {
  //         return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
  //       })
  //       .join("")
  //   );
  //   return JSON.parse(jsonPayload);
  // }

  @computed
  get isLoggedIn(): boolean {
    if (this.token === undefined) {
      return false;
    }
    if (this.token === "") {
      return false;
    }
    if (this.token === null) {
      return false;
    }
    return true;
  }

  public async login(loginData: { email: string; password: string }):Promise<boolean> {
    const { storage, authService } = this.root.services;
    const {  uiStore } = this.root.stores;
    try {
      const resp = await authService.login(loginData.email, loginData.password)
      if (resp.success === true) {
        storage.set("token", resp.data);
        runInAction(() => {
          this.token = resp.data;
        });
      }
      return true
    } catch (error) {
      uiStore.setToastErrorMessage(error.response.data.message)
      return false
    }
  }

  public async register(registrationData:Registration, password:string):Promise<boolean> {
    const {  authService } = this.root.services;
    const {  uiStore } = this.root.stores;
    try {
      registrationData.DOB = moment(registrationData.DOB).format("YYYY-MM-DDTHH:mm:ssZ")
      const resp = await authService.register(registrationData, password)
      if (resp.success === true) {
        const ok = await this.login({email:registrationData.Email, password:password})
        if (ok === true) {
          window.location.reload();
        }
      }
      return true
    } catch (error) {
      uiStore.setToastErrorMessage(error.response.data.message)
      return false
    }
  }
}

export default AuthStore;
