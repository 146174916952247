import { AxiosResponse } from "axios";
import moment from "moment";
import Entry from "../stores/models/entry";
import SpecialEntry from "../stores/models/specialEntry";
import { BaseService } from "./BaseService";

export default class EntryService extends BaseService {
  public async createEntry(entry: Entry): Promise<Entry> {
    try {
      // const body = JSON.stringify(entry);
      entry.TransactionDay = moment(entry.TransactionDay).format('YYYY-MM-DD') + 'T00:00:00Z'
      const payload = this.encodeObject<Entry>(entry, Entry);
      const url = `/api/v1/entries/`;
      const resp = await this.http.post(url, payload);
      return this.decodeObject<Entry>(resp, Entry);
    } catch (error) {
      throw error;
    }
  }
  // 2021-07-29T12:00:00+02:00 -> 2021-07-29T00:00:00+00:00
  // 2021-07-30T00:00:00+02:00 -> 2021-07-29T00:00:00+00:00
  
  public async markEntryDone(entryID:number): Promise<Entry> {
    try {
      const url = `/api/v1/entries/done/${entryID}`;
      const resp = await this.http.put(url);
      return this.decodeObject<Entry>(resp, Entry);
    } catch (error) {
      throw error;
    }
  }

  public async updateEntry(entry: Entry, future:boolean): Promise<Entry> {
    try {
      // const body = JSON.stringify(entry);
      entry.TransactionDay = moment(entry.TransactionDay).utc().format('YYYY-MM-DDT00:00:00Z')
      // if (entry.EndsOn !== null || entry.EndsOn !== ""){
      //   entry.TransactionDay = moment(entry.EndsOn).utc().format('YYYY-MM-DDT00:00:00Z')
      // }
      // if (entry.EndsOn === ""){
      //   entry.EndsOn = null
      // }
      const payload = this.encodeObject<Entry>(entry, Entry);
      const url = `/api/v1/entries/${entry.ID}?f=${future}`;
      const resp = await this.http.put(url, payload);
      return this.decodeObject<Entry>(resp, Entry);
    } catch (error) {
      throw error;
    }
  }

  public async deleteEntry(id: number, recurring:boolean) {
    try {
      // const body = JSON.stringify(entry);
      const url = `/api/v1/entries/${id}?rec=${recurring}`;
      await this.http.delete(url);
    } catch (error) {
      throw error;
    }
  }

  public async getSpecialEntries(
    month: number,
    year: number
  ): Promise<SpecialEntry[]> {
    try {
      let monthString = `${month + 1}`;
      let formattedMonthString = "";
      monthString.length > 1
        ? (formattedMonthString = monthString)
        : (formattedMonthString = `0${monthString}`);
      const resp = await this.http.get<AxiosResponse>(
        `/api/v1/entries/monthly?year=${year}&month=${formattedMonthString}`
      );
      return this.decodeArray<SpecialEntry>(resp, SpecialEntry);
    } catch (error) {
      throw error;
    }
  }

  public async getNegatives(
    offset: number,
    limit: number
  ): Promise<SpecialEntry[]> {
    try {
      const resp = await this.http.get<AxiosResponse>(
        `/api/v1/entries/negatives?limit=${limit}&offset=${offset}`
      );
      return this.decodeArray<SpecialEntry>(resp, SpecialEntry);
    } catch (error) {
      throw error;
    }
  }

  public async getSumByEntities(
    offset: number,
    limit: number
  ): Promise<Entry[]> {
    try {
      const resp = await this.http.get<AxiosResponse>(
        `/api/v1/entries/sumByEntities?limit=${limit}&offset=${offset}`
      );
      return this.decodeArray<Entry>(resp, Entry);
    } catch (error) {
      throw error;
    }
  }
  
  public async getSpecialEntriesByDay(day: string): Promise<SpecialEntry[]> {
    try {
      const resp = await this.http.get<AxiosResponse>(
        `/api/v1/entries/byDay?date=${day}`
      );
      return this.decodeArray<SpecialEntry>(resp, SpecialEntry);
    } catch (error) {
      throw error;
    }
  }

  public async fetchEntityOptions(): Promise<string[]> {
    try {
      const resp = await this.http.get<AxiosResponse>(
        `/api/v1/entries/entities`
      );
      return resp.data.data;
    } catch (error) {
      throw error;
    }
  }

  public async fetchEntry(id:number): Promise<Entry> {
    try {
      const resp = await this.http.get<AxiosResponse>(
        `/api/v1/entries/${id}`
      );
      const entry = this.decodeObject<Entry>(resp, Entry)
      return entry
    } catch (error) {
      throw error;
    }
  }
}
